<template>
  <div class="layout">
    <NewHeaderMainController hide-reward-popup />
    <main class="layout__main">
      <div v-if="isCriticalLoaded" class="layout__main-background">
        <UiImage class="layout__main-background__left" loading="lazy" :src="bgImages.left" />
        <UiImage class="layout__main-background__right" loading="lazy" :src="bgImages.right" />
      </div>
      <div class="layout__main-page">
        <slot />
      </div>
    </main>
    <FooterControllerSkeleton />
  </div>
</template>

<script setup lang="ts">
import { usePromoStore } from '~/features/payment/store/promo.store';
import { useResultsStore } from '~/features/payment/store/results.store';
import { EResultSuccessTypes, EResultTypes } from '~/features/payment/types/result/client.types';
import { useCountriesStore } from '~/features/payment/store/countries.store';
import { useBonusesStore } from '~/features/payment/store/bonuses.store';

const axeBgGray = '/static/payment/axe-bg.avif';
const axeBloggerBgGray = '/static/payment/axe-bg.avif';
const enigmaBgGray = '/static/payment/axe-bg.avif';
const enigmaBloggerBgGray = '/static/payment/axe-bg.avif';

const axeBg = '/static/payment/axe-bg.avif';
const axeBloggerBg = '/static/payment/axe-blogger-bg.avif';
const enigmaBg = '/static/payment/enigma-bg.avif';
const enigmaBloggerBg = '/static/payment/enigma-blogger-bg.avif';

const { theme } = useProjectSettings();

const route = useRoute();
const baseName = useRouteBaseName()(route);

const promoStore = usePromoStore();
const { bloggerPromo } = storeToRefs(promoStore);

const resultsStore = useResultsStore();
const { result } = storeToRefs(resultsStore);

const bonusesStore = useBonusesStore();
const { isLoaded: bonusesLoaded } = storeToRefs(bonusesStore);

const countriesStore = useCountriesStore();
const { isLoaded: countriesLoaded } = storeToRefs(countriesStore);

const isCriticalLoaded = computed(() => {
  if (baseName === ROUTING.PAYMENT.MAIN.name) {
    return countriesLoaded.value && bonusesLoaded.value;
  }

  return true;
});

const bgImages = computed(() => {
  const isResultBlogger =
    result.value?.status === EResultTypes.SUCCESS && result.value.type === EResultSuccessTypes.BLOGGER;
  const isBlogger = bloggerPromo.value || isResultBlogger;

  if (theme == 'gray') {
    return {
      left: isBlogger ? axeBloggerBgGray : axeBgGray,
      right: isBlogger ? enigmaBloggerBgGray : enigmaBgGray,
    };
  }

  return {
    left: isBlogger ? axeBloggerBg : axeBg,
    right: isBlogger ? enigmaBloggerBg : enigmaBg,
  };
});
</script>

<style lang="scss" scoped src="assets/layouts/payment.scss"></style>
